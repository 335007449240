@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-datepicker/dist/react-datepicker.css";

@layer base {
    html,
    body {
        @apply font-sans text-green-1 text-base;
    }

    h1 {
        @apply font-semibold text-42 -tracking-4;
    }
    h2 {
        @apply font-semibold text-32 -tracking-4;
    }
    h3 {
        @apply font-medium text-26 -tracking-1;
    }
    h4 {
        @apply font-semibold text-22 -tracking-1;
    }
    h5 {
        @apply font-semibold text-20 -tracking-1;
    }

    button,
    button:focus {
        @apply outline-none;
    }
    a {
        @apply outline-none focus:outline-none;
    }

    caption {
        @apply text-10 -tracking-4;
    }
    input:focus-within ~ span,
    input:not(:placeholder-shown) ~ span,
    textarea:focus-within ~ span,
    textarea:not(:placeholder-shown) ~ span,
    span.select-label {
        @apply text-12 translate-y-0 translate-x-0;
    }

    .border-separate {
        border-spacing: 0;
    }
    tr:last-of-type td {
        @apply border-b-0;
    }

    /* Hide Input [number] arrows */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}

.react-datepicker-popper {
    @apply min-w-564p bg-white pt-10 px-8 pb-8 rounded-30 shadow-datepicker;
}
.react-datepicker {
    @apply border-none font-sans flex justify-between !important;
}
.react-datepicker__month-container {
    @apply block float-none	w-full mr-10 last:mr-0  !important;
}
.react-datepicker__header {
    @apply border-none bg-white p-0 !important;
}
.react-datepicker__current-month {
    @apply text-green-1 text-15 font-medium mb-6 !important;
}
.react-datepicker__week,
.react-datepicker__day-names {
    @apply flex justify-between uppercase !important;
}
.react-datepicker__day-names .react-datepicker__day-name {
    @apply text-green-2 text-13 leading-6 !important;
}
.react-datepicker__day,
.react-datepicker__day-name {
    color: #212D3F;
    @apply flex justify-center items-center text-14 relative w-34p h-34p m-px rounded-full outline-none !important;
}
.react-datepicker__month {
    @apply m-0 !important;
}
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
    @apply bg-green-7 text-green-1 !important;
}
.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selected {
    @apply bg-green-3 text-white !important;
}
.react-datepicker__day--outside-month {
    @apply bg-white invisible !important;
}
.react-datepicker__navigation {
    @apply top-1 !important;
}
.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 6px solid #eaf6f3;
}
